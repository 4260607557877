import { IManager, IExtendedManager } from 'domain/manager/types';
import { IManagerCreate } from 'domain/manager/schemas/create';
import { IManagerUpdate } from 'domain/manager/schemas/update';

import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

export const managerApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getManagers: builder.query<Array<IManager>, void>({
      query: () => ({
        url: apiRoutes.staffManagersPath(),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.manager),
    }),
    getManager: builder.query<IExtendedManager, number>({
      query: id => ({
        url: apiRoutes.staffManagerPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.manager),
    }),
    updateManager: builder.mutation<IExtendedManager, { id: number; manager: IManagerUpdate }>({
      query: ({ id, manager }) => ({
        url: apiRoutes.staffManagerPath(id),
        method: 'PUT',
        data: manager,
      }),
      invalidatesTags: [{ type: CACHE_TAG.manager, id: 'LIST' }],
    }),
    createManager: builder.mutation<IExtendedManager, IManagerCreate>({
      query: data => ({
        url: apiRoutes.staffManagersPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.manager, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetManagersQuery,
  useGetManagerQuery,
  useUpdateManagerMutation,
  useCreateManagerMutation,
} = managerApi;
