import PropTypes from 'prop-types';
import { values, keys, isNil } from 'ramda';

import { IHeaders } from 'types/common';

import { formatPercent, formatCurrency } from 'utils/numbers';
import { formatDate } from 'utils/date';

import { ILoanMarketDiscount, ILoanMarketSummary } from './types';

export const marketDiscountsTableHeaders = [
  { maturityDate: 'Maturity Date' },
  { principalAmount: 'Principal Amount' },
  { interestRate: 'Interest Rate' },
  { purchasePrice: 'Purchase Price' },
  { purchaseYield: 'Purchase Yield' },
  { adjustedYield: 'Adjusted Yield' },
  { adjustedPrice: 'Adjusted Price' },
  { cost: 'Cost' },
  { accruedInterest: 'Accrued Interest' },
  { adjustedPurchasePrice: 'Adjusted Purchase Price' },
  { actualPurchasePrice: 'Actual Purchase Price' },
  { marketDiscount: 'Market Discount' },
  { annualMarketDiscount: 'Annual Market Discount' },
] as unknown as IHeaders;

marketDiscountsTableHeaders.getNames = () =>
  marketDiscountsTableHeaders.map(item => values(item)[0]);
marketDiscountsTableHeaders.getDataKeys = () =>
  marketDiscountsTableHeaders.map(item => keys(item)[0]);

const summaryConfig = {
  totalPrincipleAmount: 'Total Principal Amount',
  totalCost: 'Total Cost',
  totalAccruedInterest: 'Total Accrued Interest',
  totalAdjustedPurchase: 'Total Adjusted Purchase',
  totalActualPurchase: 'Total Actual Purchase',
  totalMarketDiscount: 'Total Market Discount',
  totalAnnualMarketDiscount: 'Total Annual Market Discount',
};

export const getTableRows = (data: Array<ILoanMarketDiscount>) => {
  if (isNil(data)) return null;
  return data.map(marketDiscount => ({
    id: marketDiscount.id,
    data: {
      ...marketDiscount,
      purchaseYield: formatPercent(marketDiscount?.purchaseYield, 3),
      adjustedYield: formatPercent(marketDiscount?.adjustedYield, 3),
      adjustedPrice: formatPercent(marketDiscount?.adjustedPrice, 3),
      interestRate: formatPercent(marketDiscount?.interestRate, 3),
      purchasePrice: formatPercent(marketDiscount?.purchasePrice, 6),
      maturityDate: formatDate(marketDiscount?.maturityDate),
      principalAmount: formatCurrency(marketDiscount?.principalAmount),
      cost: formatCurrency(marketDiscount?.cost),
      accruedInterest: formatCurrency(marketDiscount?.accruedInterest),
      adjustedPurchasePrice: formatCurrency(marketDiscount?.adjustedPurchasePrice),
      actualPurchasePrice: formatCurrency(marketDiscount?.actualPurchasePrice),
      marketDiscount: formatCurrency(marketDiscount?.marketDiscount),
      annualMarketDiscount: formatCurrency(marketDiscount?.annualMarketDiscount),
    },
  }));
};

export const getSummaryTableData = (data = {} as ILoanMarketSummary) =>
  Object.keys(summaryConfig).map(key => ({
    label: summaryConfig[key],
    value: formatCurrency(data[key]),
  }));

export const marketDiscountDataPropTypes = PropTypes.shape({
  id: PropTypes.number,
  maturityDate: PropTypes.string,
  principalAmount: PropTypes.string,
  interestRate: PropTypes.string,
  purchasePrice: PropTypes.string,
  purchaseYield: PropTypes.string,
  adjustedYield: PropTypes.string,
  adjustedPrice: PropTypes.string,
  cost: PropTypes.string,
  accruedInterest: PropTypes.string,
  adjustedPurchasePrice: PropTypes.string,
  actualPurchasePrice: PropTypes.string,
  marketDiscount: PropTypes.string,
  annualMarketDiscount: PropTypes.string,
});

export const summaryDataPropTypes = PropTypes.shape({
  totalPrincipleAmount: PropTypes.string,
  totalCost: PropTypes.string,
  totalAccruedInterest: PropTypes.string,
  totalAdjustedPurchase: PropTypes.string,
  totalActualPurchase: PropTypes.string,
  totalMarketDiscount: PropTypes.string,
  totalAnnualMarketDiscount: PropTypes.string,
});
