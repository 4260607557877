import { range } from 'ramda';

import { IOptions } from './types';

export const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const selectOptions = (data = {}) => Object.values(data);

export const selectStateSelectOptions = (options: IOptions) => selectOptions(options?.states);

export const getParRepayTypes = () => range(0, 11).map(n => ({ label: n, value: n }));

export const getAssumedDealsLiveFormCriteriaOptions = (options: IOptions) =>
  selectOptions(options?.assumedDealsLiveFormCriteria);

export const getOrganizationTypesOptions = (options: IOptions) =>
  selectOptions(options?.organizationTypes);

export const getOptionsForEnums = (options: IOptions) => ({
  bankAccountTypes: selectOptions(options?.bankAccountTypes),
  organizationTypesOptions: getOrganizationTypesOptions(options),
  loanStatuses: selectOptions(options?.loanStatuses),
});

export const getLoanFormOptionsTypes = (options: IOptions) => ({
  stateSelectOptions: selectOptions(options?.states),
  loanTypesOptions: selectOptions(options?.loanTypes),
  parRepayTypesOptions: getParRepayTypes(),
  loanInterestMethodTypes: selectOptions(options?.loanInterestMethodTypes),
  loanAccountantReportTypes: selectOptions(options?.loanAccountantReportTypes),
  permittedInvestmentTypes: selectOptions(options?.permittedInvestmentTypes),
  sideLetterNatureTypes: selectOptions(options?.sideLetterNatureTypes),
  monetContractPercentages: selectOptions(options?.monetContractPercentages),
  partyToDesignateSBTypes: selectOptions(options?.partyToDesignateSBTypes),
  loanThirdPartyFeesStatuses: selectOptions(options?.loanThirdPartyFeesStatuses),
  securitiesUserTypes: selectOptions(options?.securitiesUserTypes),
});
