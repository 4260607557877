/* eslint-disable react/prop-types */
import { StatusConst } from 'const';

import { STATUS_COLUMN } from 'enums';

import AddressPresenter from 'presenters/AddressPresenter';
import BankAccountPresenter from 'presenters/BankAccountPresenter';

import { IBorrowerConfigurationResponse } from './types';

export const createDefaultValues = (configuration = {} as IBorrowerConfigurationResponse) => {
  const {
    parentCompanyId,
    administrativeAgentId,
    ein,
    einDateFiledOn,
    einVerificationLetterRecordDate,
    fiscalYear,
    jurisdiction,
    registeredAgentId,
    bankAccount,
    comments,
    companyAddress,
    registeredOfficeAddress,
  } = configuration;

  return {
    status: StatusConst[STATUS_COLUMN.active],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore //To ignore PropTypes files (doesn't merge with typescript)
    bankAccount: BankAccountPresenter.defaultValues(bankAccount),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore //To ignore PropTypes files (doesn't merge with typescript)
    address: AddressPresenter.defaultValues(companyAddress),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore //To ignore PropTypes files (doesn't merge with typescript)
    registeredOfficeAddress: AddressPresenter.defaultValues(registeredOfficeAddress),
    parentCompany: parentCompanyId,
    administrativeAgent: administrativeAgentId,
    ein,
    einDateFiledOn,
    einVerificationLetterRecordDate,
    fiscalYear,
    jurisdiction,
    comments,
    registeredAgent: registeredAgentId,
  };
};
