import { camelize } from 'humps';

import { LoanStatus } from 'domain/loan/enums';

import { formatCurrency } from 'utils/numbers';

import { IAssumeLoan, ISnapshot } from './schemas/common';
import { IGlobalSnapshot } from './types';

const getStructureRates = (rates: Array<IAssumeLoan> = []) =>
  rates.reduce(
    (acc, rate) => {
      acc.labels.push(rate.month);
      acc.twoYearsData.push(rate.twoYearsAgo);
      acc.lastYearData.push(rate.lastYear);
      acc.currentYearData.push(rate.thisYear);
      return acc;
    },
    { labels: [], twoYearsData: [], lastYearData: [], currentYearData: [] },
  );

export const getRates = (rates: Array<IAssumeLoan> = []) => {
  const structuredRates = getStructureRates(rates);

  return {
    dataset: [
      {
        label: '2 Years Ago',
        data: structuredRates?.twoYearsData,
        backgroundColor: '#FFC90B',
      },
      {
        label: 'Last Year',
        data: structuredRates?.lastYearData,
        backgroundColor: '#4D95D8',
      },
      {
        label: 'This Year',
        data: structuredRates?.currentYearData,
        backgroundColor: '#EF476F',
      },
    ],
    labels: structuredRates.labels,
  };
};

const getSnapshot = (data: IGlobalSnapshot): ISnapshot => data?.snapshot;

export const getGlobalSnapshotData = (data: IGlobalSnapshot) => {
  const snapshot = getSnapshot(data) as ISnapshot;
  const loanCountByStatus: Record<string, LoanStatus> =
    snapshot?.loanCountByStatus?.statuses.reduce((acc, item) => {
      const status = camelize(item.status);
      return { [status]: item.amount, ...acc };
    }, {});
  return {
    lists: [
      [
        {
          label: 'Total Successor Borrowers',
          value: snapshot?.successorBorrowers?.allSuccessorBorrowers,
        },
        {
          label: 'Active',
          value: snapshot?.successorBorrowers?.active,
        },
        {
          label: 'Inactive',
          value: snapshot?.successorBorrowers?.inactive,
        },
      ],
      [
        {
          label: 'Total Loans',
          value: snapshot?.loanCountByStatus?.total,
        },
        {
          label: 'Total In Progress',
          value: loanCountByStatus?.inProgress,
        },
        {
          label: 'Total Active In Payment',
          value: loanCountByStatus?.activeInPayment,
        },
        {
          label: 'Active In Payment CDHC',
          value: loanCountByStatus?.activeInPaymentCdhc,
        },
        {
          label: 'Active In Payment CDIH',
          value: loanCountByStatus?.activeInPaymentCdih,
        },
        {
          label: 'Total Inactive',
          value: loanCountByStatus?.inactive,
        },
        {
          label: 'Total Active Paid',
          value: loanCountByStatus?.activePaid,
        },
        {
          label: 'Total On Hold',
          value: loanCountByStatus?.indefinitelyOnHold,
        },
      ],
    ],
    summary: [
      {
        label: 'Unpaid Principal Balance',
        value: formatCurrency(snapshot?.unpaidPrincipalBalance),
      },
      {
        label: 'Outstanding Securities Balance',
        value: formatCurrency(snapshot?.outstandingSecuritiesBalance),
      },
      {
        label: 'Total Securities Cost',
        value: formatCurrency(snapshot?.securitiesCost),
      },
      {
        label: 'Total Original Loan Amount',
        value: formatCurrency(snapshot?.originalLoanAmount),
      },
      {
        label: 'Total Principal Balance at Time of Defeasance',
        value: formatCurrency(snapshot?.principalBalanceAtTimeOfDefeasance),
      },
    ],
  };
};

export const getYtdData = (data: IGlobalSnapshot) => {
  const snapshot = getSnapshot(data) as ISnapshot;
  return {
    lists: [
      [
        {
          label: 'New Deals In YTD',
          values: [
            snapshot?.newDealsYearToDate?.current.yearToDate,
            snapshot?.newDealsYearToDate?.yearOverYear.yearToDate,
          ],
          diff: snapshot?.newDealsYearToDate?.difference.yearToDate,
        },
        {
          label: 'This Month',
          values: [
            snapshot?.newDealsYearToDate?.current.month,
            snapshot?.newDealsYearToDate?.yearOverYear.month,
          ],
          diff: snapshot?.newDealsYearToDate?.difference.month,
        },
        {
          label: 'This Week',
          values: [
            snapshot?.newDealsYearToDate?.current.week,
            snapshot?.newDealsYearToDate?.yearOverYear.week,
          ],
          diff: snapshot?.newDealsYearToDate?.difference.week,
        },
      ],
      [
        {
          label: 'Deals Closed YTD',
          values: [
            snapshot?.closedDealsYearToDate?.current.yearToDate,
            snapshot?.closedDealsYearToDate?.yearOverYear.yearToDate,
          ],
          diff: snapshot?.closedDealsYearToDate?.difference.yearToDate,
        },
        {
          label: 'This Month',
          values: [
            snapshot?.closedDealsYearToDate?.current.month,
            snapshot?.closedDealsYearToDate?.yearOverYear.month,
          ],
          diff: snapshot?.closedDealsYearToDate?.difference.month,
        },
        {
          label: 'This Week',
          values: [
            snapshot?.closedDealsYearToDate?.current.week,
            snapshot?.closedDealsYearToDate?.yearOverYear.week,
          ],
          diff: snapshot?.closedDealsYearToDate?.difference.week,
        },
      ],
    ],
  };
};

export const getTotalAssumedYTDData = (data: IGlobalSnapshot) => {
  const snapshot = getSnapshot(data);
  return {
    lists: [
      [
        {
          label: 'Total Assumed YTD',
          values: [snapshot?.totalAssumedYearToDate?.total],
          diff: null,
        },
        {
          label: 'DHC ONLY Assumed YTD',
          values: [snapshot?.totalAssumedYearToDate?.dhcOnly],
          diff: null,
        },
        {
          label: 'CD Assumed YTD',
          values: [snapshot?.totalAssumedYearToDate?.other],
          diff: null,
        },
      ],
      [
        {
          label: 'Total In Progress',
          values: [snapshot?.totalInProgressYearToDate?.total],
          diff: null,
        },
        {
          label: 'DHC ONLY In Progress',
          values: [snapshot?.totalInProgressYearToDate?.dhcOnly],
          diff: null,
        },
        {
          label: 'Other',
          values: [snapshot?.totalInProgressYearToDate?.other],
          diff: null,
        },
      ],
    ],
  };
};
