import { values, keys, isNil, pipe, map } from 'ramda';
import PropTypes from 'prop-types';

import { IHeaders } from 'types/common';

import { formatCurrency, isZeroOrNull } from 'utils/numbers';
import { formatDateFromTo } from 'utils/date';
import { formatDate } from 'utils/date';
import { formatBoolean } from 'utils/booleans';
import { isBlank } from 'utils/predicates';

import { IReconciliationInput, IReconciliationInputSummary } from './types';

export const columns = [
  { reconciliationYearMonth: 'Month' },
  { balancePerCustodian: 'Balance Per Custodian' },
  { floatInterest: 'Float Interest' },
  { custodianFee: 'Custodian Fee' },
  { other: 'Other' },
  { isApprovedForRec: 'Approved For Rec' },
  { reconciliationDiscrepancy: 'Reconciliation Discrepancies' },
  { action: 'Action' },
] as unknown as IHeaders;

columns.getNames = () => columns.map(item => values(item)[0]);
columns.getDataKeys = () => columns.map(item => keys(item)[0]).filter(key => key !== 'action');

export const createTableRows = (list: Array<IReconciliationInput>) => {
  if (isNil(list)) return null;
  return list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      reconciliationYearMonth: formatDateFromTo(rowData.reconciliationYearMonth, 'y-M', 'MMMM y'),
      balancePerCustodian: formatCurrency(rowData.balancePerCustodian),
      floatInterest: formatCurrency(rowData.floatInterest),
      custodianFee: formatCurrency(rowData.custodianFee),
      other: formatCurrency(rowData.other),
      isApprovedForRec: formatBoolean(rowData.isApprovedForRec),
      reconciliationDiscrepancy: formatCurrency(rowData.reconciliationDiscrepancy),
    },
    properties: {
      reconciliationDiscrepancy: {
        isHighlighted: !isZeroOrNull(rowData.reconciliationDiscrepancy),
      },
    },
  }));
};

export const getTableSummary = (
  summary: IReconciliationInputSummary,
  loanItem: IReconciliationInput,
) => {
  if (isBlank(summary) || isBlank(loanItem)) return null;
  return [
    {
      label: 'Total Float Interest Collected (for all years)',
      value: formatCurrency(summary.totalFloatInterest),
    },
    {
      label: 'Custodian Fee (from Loan Details)',
      value: formatCurrency(loanItem.custodianFee),
    },
    {
      label: 'Custodian Account Number',
      value: summary.custodianAccountNumber,
    },
    {
      label: 'Assumed Date',
      value: formatDate(summary.assumedDate),
    },
  ];
};

export const defaultYearSelectOption = { option: null, label: 'All' };

const createOption = (year: string) => ({
  option: Number(year),
  label: String(year),
});

export const createYearSelectOptions = (reconciliationInputs: Array<{ year: string }>) => {
  const result = pipe(
    map(item => item.year),
    map(createOption),
  )(reconciliationInputs ?? []);
  return [defaultYearSelectOption, ...result];
};

export const reconciliationInputPropTypes = PropTypes.shape({
  id: PropTypes.number,
  reconciliationYearMonth: PropTypes.string,
  balancePerCustodian: PropTypes.string,
  floatInterest: PropTypes.string,
  custodianFee: PropTypes.string,
  other: PropTypes.string,
  isApprovedForRec: PropTypes.bool,
  comment: PropTypes.string,
  reconciliationDiscrepancy: PropTypes.string,
  loanId: PropTypes.number,
});
