import { StatusDisplayedNameByCode } from 'const';
import { StatusConst } from 'const';

import { IExtendedManager } from 'domain/manager/types';
import { IManagerValuesConfig } from 'domain/manager/schemas/common';

import { CELL_TYPES, STATUS_COLUMN } from 'enums';

import BankAccountPresenter from 'presenters/BankAccountPresenter';
import AddressPresenter from 'presenters/AddressPresenter';

import { getAddressString } from 'utils/dataTransform';
import { formatDate } from 'utils/date';

export const managerColumnConfig = {
  status: {
    displayedName: 'Status',
  },
  statusNote: {
    displayedName: 'Status Note',
  },
  parentCompany: {
    displayedName: 'Parent Company',
  },
  administrativeAgent: {
    displayedName: 'Administrative Agent',
  },
  ein: {
    displayedName: 'EIN',
  },
  einDateFiledOn: {
    displayedName: 'EIN Filed Date',
  },
  form2553FiledDate: {
    displayedName: '2553 Filed Date',
  },
  form2553AcceptedDate: {
    displayedName: '2553 Accepted Date',
  },
  secretaryOfStateId: {
    displayedName: 'SOS ID',
  },
  sosAcceptedDate: {
    displayedName: 'SOS Accepted Date',
  },
  jurisdiction: {
    displayedName: 'Jurisdiction',
  },
  address: {
    displayedName: 'Company Address',
  },
  fiscalYear: {
    displayedName: 'Fiscal Year Ending',
  },
  registeredAgent: {
    displayedName: 'Registered Agent',
  },
  registeredOfficeAddress: {
    displayedName: 'Registered Office Address',
  },
  dateOfDissolutionOn: {
    displayedName: 'Date of Dissolution',
  },
  bankAccount: {
    displayedName: 'Bank Account',
    cellType: CELL_TYPES.information,
  },
};

export const getTableData = (data: IExtendedManager) => {
  if (!data) {
    return {};
  }
  return {
    ...data,
    registeredOfficeAddress: getAddressString(data.registeredOfficeAddress),
    status: StatusDisplayedNameByCode[data.status],
    address: getAddressString(data.address),
    administrativeAgent: data?.administrativeAgent.name,
    registeredAgent: data?.registeredAgent?.name,
    parentCompany: data?.parentCompany.name,
    sosAcceptedDate: formatDate(data?.sosAcceptedDate),
    einDateFiledOn: formatDate(data?.einDateFiledOn),
    dateOfDissolutionOn: formatDate(data?.dateOfDissolutionOn),
    form2553FiledDate: formatDate(data?.form2553FiledDate),
    form2553AcceptedDate: formatDate(data?.form2553AcceptedDate),
    enteredDate: formatDate(data?.enteredDate),
    bankAccount: {
      title: data.bankAccount?.name,
      fields: BankAccountPresenter.tableData(data?.bankAccount),
    },
  };
};

export const getDefaultValues = (configuration: IManagerValuesConfig = null) => {
  if (
    configuration &&
    'companyAddress' in configuration &&
    'registeredOfficeAddress' in configuration
  ) {
    const { companyAddress, registeredOfficeAddress } = configuration;
    return {
      address: AddressPresenter.defaultValues(companyAddress),
      registeredOfficeAddress: AddressPresenter.defaultValues(registeredOfficeAddress),
      status: StatusConst[STATUS_COLUMN.active],
      bankAccount: BankAccountPresenter.defaultValues(),
    };
  }
  return {};
};
