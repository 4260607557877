export const columnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
    canBeHidden: false,
  },
  id: {
    displayedName: 'PID',
    canBeHidden: false,
    isLink: true,
  },
  dealName: {
    displayedName: 'Property Name',
    canBeHidden: false,
    withTags: true,
  },
  assumedDate: {
    displayedName: 'Accepted Date',
    canBeHidden: false,
  },
  bloombergName: {
    displayedName: 'Bloomberg Name',
  },
  status: {
    displayedName: 'Status',
    canBeHidden: false,
  },
  loanServicerName: {
    displayedName: 'Sub Servicer',
    canBeHidden: false,
  },
  servicerCounsel1Name: {
    displayedName: 'Servicer Counsel Contact 1',
  },
};

export const getSbLoansTableHeader = () => Object.keys(columnConfig);
