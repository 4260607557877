import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

import { IPersonChoiceQueryParams, IPersonChoice } from './types';

export const personChoicesApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getPersonChoices: builder.query<Array<IPersonChoice>, IPersonChoiceQueryParams>({
      query: params => ({
        url: apiRoutes.personsChoicesPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.personChoice),
    }),
  }),
});

export const { useGetPersonChoicesQuery } = personChoicesApi;
