import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';

import { IGlobalSnapshot } from './types';

export const globalSnapshotApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getGlobalSnapshot: builder.query<IGlobalSnapshot, void>({
      query: params => ({
        url: apiRoutes.globalSnapshotPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.globalSnapshot],
    }),
  }),
});

export const { useGetGlobalSnapshotQuery } = globalSnapshotApi;
