import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { BooleanFieldLabels } from 'const';

import { IExtendedPool } from 'domain/pool/types';

import { shortDescriptionModel } from 'utils/PropTypesPresenter';
import { formatCurrency } from 'utils/numbers';
import { formatDate } from 'utils/date';

export const poolColumnConfig = {
  cusip: {
    displayedName: 'Cusip',
  },
  currentPrice: {
    displayedName: 'Bloomberg Pool Balance',
  },
  originalIssuePrice: {
    displayedName: 'Original Pool Issue',
  },
  bloombergPoolBalanceDate: {
    displayedName: 'Bloomberg Pool Balance Date',
  },
  bondTrustee: {
    displayedName: 'Bond Trustee',
  },
  masterServicer1: {
    displayedName: 'Master Servicer 1',
  },
  masterServicer2: {
    displayedName: 'Master Servicer 2',
  },
  masterServicerNotes: {
    displayedName: 'Master Servicer Notes',
  },
  ratingAgency1: {
    displayedName: 'Rating Agency 1',
  },
  ratingAgency2: {
    displayedName: 'Rating Agency 2',
  },
  ratingAgency3: {
    displayedName: 'Rating Agency 3',
  },
  ratingAgency4: {
    displayedName: 'Rating Agency 4',
  },
  isIndemnityGiven: {
    displayedName: 'Indemnity Given?',
  },
  indemnityNotes: {
    displayedName: 'Indemnity Notes',
  },
  indemnityDate: {
    displayedName: 'Indemnity Date',
  },
  indemnitor: {
    displayedName: 'Indemnitor',
  },
  indemnitySentFor: {
    displayedName: 'Indemnity Sent For',
  },
  issuerAbbreviation: {
    displayedName: 'Issuer Abbreviation',
  },
  issuerSeries: {
    displayedName: 'Issuer Series',
  },
  issuerName: {
    displayedName: 'Issuer Name',
  },
  certificateDefinition: {
    displayedName: 'Certificate Definition',
  },
  certificateHolderLabel: {
    displayedName: 'Certificate Holder Label',
  },
  specialServicer: {
    displayedName: 'Special Servicer',
  },
  fiscalAgent: {
    displayedName: 'Fiscal Agent',
  },
  psaDate: {
    displayedName: 'PSA Date (Month DD, YYYY)',
  },
  hasPoolSecuritized: {
    displayedName: 'Securitized?',
  },
  sbDesignation: {
    displayedName: 'SB Designation',
  },
};

export const getTableData = (data: IExtendedPool) => {
  if (isNil(data)) return null;
  return {
    ...data,
    currentPrice: data.currentPrice && formatCurrency(data.currentPrice),
    originalIssuePrice: data.originalIssuePrice && formatCurrency(data.originalIssuePrice),
    bloombergPoolBalanceDate: formatDate(data.bloombergPoolBalanceDate),
    indemnityDate: formatDate(data.indemnityDate),
    isIndemnityGiven: BooleanFieldLabels[String(data?.isIndemnityGiven)],
    updatedAt: formatDate(data.updatedAt),
    bondTrustee: data?.bondTrustee?.name,
    masterServicer1: data?.masterServicer1?.name,
    masterServicer2: data?.masterServicer2?.name,
    ratingAgency1: data?.ratingAgency1?.name,
    ratingAgency2: data?.ratingAgency2?.name,
    ratingAgency3: data?.ratingAgency3?.name,
    ratingAgency4: data?.ratingAgency4?.name,
    sbDesignation: data?.sbDesignation,
    indemnitySentFor: data?.indemnitySentFor?.name,
    hasPoolSecuritized: BooleanFieldLabels[String(data?.hasPoolSecuritized)],
  };
};

export const getDefaultValues = () => ({
  hasPoolSecuritized: true,
  isIndemnityGiven: false,
});

export const poolPropTypes = PropTypes.shape({
  bloombergPoolBalanceDate: PropTypes.string,
  bondTrustee: shortDescriptionModel,
  certificateDefinition: PropTypes.string,
  certificateHolderLabel: PropTypes.string,
  currentPrice: PropTypes.string,
  cusip: PropTypes.string,
  fiscalAgent: PropTypes.string,
  hasPoolSecuritized: PropTypes.bool,
  id: PropTypes.number,
  indemnitor: PropTypes.string,
  indemnityDate: PropTypes.string,
  indemnityNotes: PropTypes.string,
  indemnitySentFor: shortDescriptionModel,
  isIndemnityGiven: PropTypes.bool,
  isLocked: PropTypes.bool,
  issuerAbbreviation: PropTypes.string,
  issuerName: PropTypes.string,
  issuerSeries: PropTypes.string,
  masterServicer1: shortDescriptionModel,
  masterServicer2: shortDescriptionModel,
  masterServicerNotes: PropTypes.string,
  originalIssuePrice: PropTypes.string,
  psaDate: PropTypes.string,
  ratingAgency1: shortDescriptionModel,
  ratingAgency2: shortDescriptionModel,
  ratingAgency3: shortDescriptionModel,
  ratingAgency4: shortDescriptionModel,
  sbDesignation: PropTypes.string,
  securityDescription: PropTypes.string,
  specialServicer: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.shape({ id: PropTypes.number, username: PropTypes.string }),
});
