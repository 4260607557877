import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

import { IExtendedOrganization, IOrganization } from './types';
import { IOrganizationCreate } from './schemas/create';
import { IOrganizationUpdate } from './schemas/update';
import { IOrganizationQueryParams } from './schemas/common';

export const organizationApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getOrganizations: builder.query<
      IOrganization,
      Partial<IOrganizationQueryParams | string> | void
    >({
      query: params => ({
        url: apiRoutes.organizationsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.organization),
    }),
    getOrganization: builder.query<IExtendedOrganization, number>({
      query: id => ({
        url: apiRoutes.partiesOrganizationPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.organization),
    }),
    updateOrganization: builder.mutation<
      IOrganization,
      { id: number; organization: IOrganizationUpdate }
    >({
      query: ({ id, organization }) => ({
        url: apiRoutes.partiesOrganizationPath(id),
        method: 'PUT',
        data: organization,
      }),
      invalidatesTags: [{ type: CACHE_TAG.organization, id: 'LIST' }],
    }),
    createOrganization: builder.mutation<IOrganization, IOrganizationCreate>({
      query: data => ({
        url: apiRoutes.organizationsPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.organization, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useCreateOrganizationMutation,
} = organizationApi;
