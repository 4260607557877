import { isNil, filter } from 'ramda';

import PersonIndexPresenter from 'presenters/PersonIndexPresenter';

import { getSelectOptions } from 'utils/getSelectOptions';

import { IOrganization } from './types';
import { OrganizationType } from './enums';

export const filterOrganizationsOptions = (
  organizationType: OrganizationType,
  organizationsData: Array<IOrganization>,
) => {
  if (isNil(organizationsData)) return [];
  return getSelectOptions(
    organizationsData.filter(item => item.organizationType === organizationType),
  );
};

export const getOrganizationsTableData = (list: Array<IOrganization> = []) =>
  list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
    },
  }));

const filteredOrganizations = (
  organizationType: OrganizationType,
  organizations: Array<IOrganization>,
) => filter(item => !organizationType || item.organizationType === organizationType, organizations);

export const getFilteredTableData = (
  organizationType: OrganizationType,
  organizations: Array<IOrganization>,
) => getOrganizationsTableData(filteredOrganizations(organizationType, organizations));

export const getOrganizationTableData = data => {
  if (!data) {
    return {};
  }
  return {
    ...data,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore // PropTypes doesn't merge with typescript
    employees: PersonIndexPresenter.tableData(data.employees),
    organizationType: data?.organizationType?.name,
  };
};
