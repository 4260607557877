import { StatusDisplayedNameByCode } from 'const';

import { formatDate } from 'utils/date';
import { getSelectOptions } from 'utils/getSelectOptions';

import { IAdministrativeAgent } from '../types';

export const administrativeAgentsColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  name: {
    displayedName: 'Company Name',
    isLink: true,
  },
  status: {
    displayedName: 'Status',
  },
  ein: {
    displayedName: 'Ein',
  },
  secretaryOfStateId: {
    displayedName: 'SOS Id',
  },
  sosAcceptedDate: {
    displayedName: 'SOS Date',
  },
};

export const getTableData = (list: Array<IAdministrativeAgent> = []) =>
  list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      status: StatusDisplayedNameByCode[rowData.status],
      sosAcceptedDate: formatDate(rowData.sosAcceptedDate),
    },
  }));

export const getTableKeys = () => Object.keys(administrativeAgentsColumnConfig);

export const administrativeAgentOptions = (administrativeAgents: Array<IAdministrativeAgent>) =>
  getSelectOptions(administrativeAgents, 'id');
