import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

import { IClosedDeal } from './types';
import { IDealItem } from './schemas/types';

export const recentlyClosedDealApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getRecentlyClosedDeals: builder.query<IClosedDeal<IDealItem>, void>({
      query: params => ({
        url: apiRoutes.recentlyClosedDealsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.index(result?.items, CACHE_TAG.recentlyClosedDeal),
    }),
  }),
});

export const { useGetRecentlyClosedDealsQuery } = recentlyClosedDealApi;
