import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';

import { IOptions } from './types';

export const optionsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getOptions: builder.query<IOptions, void>({
      query: () => ({
        url: apiRoutes.optionsPath(),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: () => [{ type: CACHE_TAG.option, id: 'LIST' }],
    }),
  }),
});

export const { useGetOptionsQuery } = optionsApi;
