import PropTypes from 'prop-types';
import { values, keys, isNil } from 'ramda';

import { IHeaders } from 'types/common';

import { formatPercent, formatCurrency } from 'utils/numbers';
import { formatDate } from 'utils/date';

import { IDefeasedPayment, IDefeasedPaymentSummary } from './types';

export const defeasedPaymentsHeaders = [
  { paymentDate: 'Payment Date' },
  { principalBalance: 'Principal Balance' },
  { principalPaid: 'Principal Paid' },
  { interestRate: 'Interest Rate' },
  { interest: 'Interest' },
  { totalLoanPayments: 'Total Loan Payments' },
] as unknown as IHeaders;

defeasedPaymentsHeaders.getNames = () => defeasedPaymentsHeaders.map(item => values(item)[0]);
defeasedPaymentsHeaders.getDataKeys = () => defeasedPaymentsHeaders.map(item => keys(item)[0]);

const summaryConfig = {
  totalOfPayments: 'Total Of Payments',
  totalPrincipalPaid: 'Total Principal Paid',
  totalInterestPaid: 'Total Interest Paid',
};

export const createTableRows = (data: Array<IDefeasedPayment>) => {
  if (isNil(data)) return null;
  return data.map(defeasedPayment => ({
    id: defeasedPayment.id,
    data: {
      ...defeasedPayment,
      paymentDate: formatDate(defeasedPayment?.paymentDate),
      interest: formatCurrency(defeasedPayment?.interest),
      principalBalance: formatCurrency(defeasedPayment?.principalBalance),
      principalPaid: formatCurrency(defeasedPayment?.principalPaid),
      interestRate: formatPercent(defeasedPayment?.interestRate, 3),
      totalLoanPayments: formatCurrency(defeasedPayment?.totalLoanPayments),
    },
  }));
};

export const getHeaders = () => defeasedPaymentsHeaders;

export const getSummaryTableData = (data = {} as IDefeasedPaymentSummary) =>
  Object.keys(summaryConfig).map(key => ({
    label: summaryConfig[key],
    value: formatCurrency(data[key]),
  }));

export const defeasedPaymentsDataPropTypes = PropTypes.shape({
  id: PropTypes.number,
  paymentDate: PropTypes.string,
  principalBalance: PropTypes.string,
  principalPaid: PropTypes.string,
  interestRate: PropTypes.number,
  interest: PropTypes.string,
  totalLoanPayments: PropTypes.string,
});

export const summaryPropTypes = {
  totalOfPayments: PropTypes.string,
  totalPrincipalPaid: PropTypes.string,
  totalInterestPaid: PropTypes.string,
};
