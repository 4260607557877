import { isNil } from 'ramda';

import { IExtendedLoan } from 'domain/extendedLoan/types';

import { formatDate, isBeforeTodayDate } from 'utils/date';

export const upcomingMaturitiesColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  id: {
    displayedName: 'PID',
  },
  dealName: {
    displayedName: 'Property Name',
    isLink: true,
    withTags: true,
  },
  parRepayDate: {
    displayedName: 'Par Repay Date',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  masterServicerShortName: {
    displayedName: 'Master Short',
  },
  masterServicerLoanNumber: {
    displayedName: 'Master #',
  },
  alternativeServicerShortName: {
    displayedName: 'Sub Short',
  },
  loanServicerLoanNumber: {
    displayedName: 'Sub Loan #',
  },
  custodianShortName: {
    displayedName: 'Custodian',
  },
  custodianAccountNumber: {
    displayedName: 'Custodian #',
    width: '100px',
  },
};

export const getColumnsKeys = () => Object.keys(upcomingMaturitiesColumnConfig);

export const getTableData = (list: Array<IExtendedLoan>) => {
  if (isNil(list)) return null;
  return list.map(rowData => ({
    id: rowData.id,
    isHighlighted: isBeforeTodayDate(rowData.maturityDate),
    data: {
      ...rowData,
      parRepayDate: formatDate(rowData.parRepayDate),
      maturityDate: formatDate(rowData.maturityDate),
      parRepayNoticeLender: formatDate(rowData.parRepayNoticeLenderDate),
      parRepayNoticeCustodian: formatDate(rowData.parRepayNoticeCustodianDate),
    },
  }));
};
