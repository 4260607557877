import { StatusConst, StatusDisplayedNameByCode } from 'const';

import { CELL_TYPES, STATUS_COLUMN } from 'enums';

import BankAccountPresenter from 'presenters/BankAccountPresenter';
import AddressPresenter from 'presenters/AddressPresenter';

import { getAddressString } from 'utils/dataTransform';
import { formatDate } from 'utils/date';

import { IExtendedAdministrativeAgent } from '../types';
import { IAdministrativeAgentConfiguration } from '../schemas/common';

export const administrativeAgentColumnConfig = {
  status: {
    displayedName: 'Status',
  },
  statusNote: {
    displayedName: 'Status Note',
  },
  ein: {
    displayedName: 'EIN',
  },
  einDateFiledOn: {
    displayedName: 'EIN Filed Date',
  },
  form2553FiledDate: {
    displayedName: '2553 Filed Date',
  },
  form2553AcceptedDate: {
    displayedName: '2553 Accepted Date',
  },
  secretaryOfStateId: {
    displayedName: 'SOS ID',
  },
  sosAcceptedDate: {
    displayedName: 'SOS Accepted Date',
  },
  jurisdiction: {
    displayedName: 'Jurisdiction',
  },
  address: {
    displayedName: 'Company Address',
  },
  fiscalYear: {
    displayedName: 'Fiscal Year Ending',
  },
  registeredAgent: {
    displayedName: 'Registered Agent',
  },
  registeredOfficeAddress: {
    displayedName: 'Registered Office Address',
  },
  dateOfDissolutionOn: {
    displayedName: 'Date of Dissolution',
  },
  bankAccount: {
    displayedName: 'Bank Account',
    cellType: CELL_TYPES.information,
  },
};

export const getTableData = (data: IExtendedAdministrativeAgent) => {
  if (!data) return null;
  return {
    ...data,
    registeredOfficeAddress: getAddressString(data?.registeredOfficeAddress),
    status: StatusDisplayedNameByCode[data?.status],
    address: getAddressString(data?.address),
    sosAcceptedDate: formatDate(data?.sosAcceptedDate),
    einDateFiledOn: formatDate(data?.einDateFiledOn),
    dateOfDissolutionOn: formatDate(data?.dateOfDissolutionOn),
    form2553FiledDate: formatDate(data?.form2553FiledDate),
    form2553AcceptedDate: formatDate(data?.form2553AcceptedDate),
    enteredDate: formatDate(data?.enteredDate),
    registeredAgent: data?.registeredAgent?.name,
    bankAccount: {
      title: data.bankAccount?.name,
      // ignore TypeScript & PropTypes lib conflict, will be fixed in task #2200
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fields: BankAccountPresenter.tableData(data?.bankAccount),
    },
  };
};

export const getDefaultValues = (configuration = {} as IAdministrativeAgentConfiguration) => {
  const { companyAddress, registeredOfficeAddress } = configuration;

  return {
    status: StatusConst[STATUS_COLUMN.active],
    // ignore TypeScript & PropTypes lib conflict, will be fixed in task #2200
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    bankAccount: BankAccountPresenter.defaultValues(),
    // ignore TypeScript & PropTypes lib conflict, will be fixed in task #2200
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    address: AddressPresenter.defaultValues(companyAddress),
    // ignore TypeScript & PropTypes lib conflict, will be fixed in task #2200
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    registeredOfficeAddress: AddressPresenter.defaultValues(registeredOfficeAddress),
  };
};
