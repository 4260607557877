import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag, invalidateOnSuccess } from 'utils/rtkQuery';

import { IReconciliationInput, IReconciliationInputSummary } from './types';
import { IReconciliationCreate } from './schemas/create';
import { IReconciliationUpdate } from './schemas/update';
import { IReconciliationInputsQueryParams } from './schemas/common';

const tagsToInvalidate = [
  CACHE_TAG.reconciliationInput,
  CACHE_TAG.reconciliationInputsSummary,
  CACHE_TAG.reconciliationInputsAvailableYears,
];

export const reconciliationInputApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReconciliationInputs: builder.query<IReconciliationInput, IReconciliationInputsQueryParams>({
      query: params => ({
        url: apiRoutes.reconciliationInputsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.reconciliationInput),
    }),
    createReconciliationInput: builder.mutation<IReconciliationInput, IReconciliationCreate>({
      query: data => ({
        url: apiRoutes.reconciliationInputsPath(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateOnSuccess(tagsToInvalidate),
    }),
    updateReconciliationInput: builder.mutation<
      IReconciliationInput,
      { data: IReconciliationUpdate; id: number }
    >({
      query: ({ data, id }) => ({
        url: apiRoutes.reconciliationInputPath(id),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOnSuccess(tagsToInvalidate),
    }),
    deleteReconciliationInput: builder.mutation<void, number>({
      query: id => ({
        url: apiRoutes.reconciliationInputPath(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOnSuccess(tagsToInvalidate),
    }),
    getReconciliationInputsSummary: builder.query<IReconciliationInputSummary, number>({
      query: id => ({
        url: apiRoutes.loansReconciliationInputsSummaryPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.reconciliationInputsSummary],
    }),
    getReconciliationInputsAvailableYears: builder.query<{ year: string }, number>({
      query: id => ({
        url: apiRoutes.loansReconciliationInputsAvailableYearsPath(id),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: [CACHE_TAG.reconciliationInputsAvailableYears],
    }),
  }),
});

export const {
  useGetReconciliationInputsQuery,
  useGetReconciliationInputsSummaryQuery,
  useGetReconciliationInputsAvailableYearsQuery,
  useCreateReconciliationInputMutation,
  useUpdateReconciliationInputMutation,
  useDeleteReconciliationInputMutation,
} = reconciliationInputApi;
