import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

import { IPoolChoice } from './types';

export const poolsChoicesApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getPoolsChoices: builder.query<IPoolChoice, void>({
      query: () => ({
        url: apiRoutes.poolsChoicesPath(),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.poolChoice),
    }),
  }),
});

export const { useGetPoolsChoicesQuery } = poolsChoicesApi;
