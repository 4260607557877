import PropTypes from 'prop-types';
import { values, keys, isNil } from 'ramda';

import { IHeaders } from 'types/common';

import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/numbers';

import { IEscrowAccount } from './types';

export const escrowAccountHeaders = [
  { entryDate: 'Date' },
  { cashReceipt: 'Cash Receipt From Securities' },
  { loanPayment: 'Payment On The Defeased Loan' },
  { cashBalance: 'Cash Balance' },
] as unknown as IHeaders;

escrowAccountHeaders.getNames = () => escrowAccountHeaders.map(item => values(item)[0]);
escrowAccountHeaders.getDataKeys = () => escrowAccountHeaders.map(item => keys(item)[0]);

export const createTableRows = (data: Array<IEscrowAccount>) => {
  if (isNil(data)) return null;
  return data.map(escrowAccount => ({
    id: escrowAccount.id,
    data: {
      entryDate: formatDate(escrowAccount?.entryDate),
      cashReceipt: formatCurrency(escrowAccount?.cashReceipt),
      loanPayment: formatCurrency(escrowAccount?.loanPayment),
      cashBalance: formatCurrency(escrowAccount?.cashBalance),
    },
  }));
};

export const loanEscrowAccountPropTypes = PropTypes.shape({
  entryDate: PropTypes.string,
  cashReceipt: PropTypes.string,
  loanPayment: PropTypes.string,
  cashBalance: PropTypes.string,
  id: PropTypes.number,
});
