import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';

import { IHelpFile } from './types';

export const helpFileApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getHelpFile: builder.query<IHelpFile, void>({
      query: () => ({
        url: apiRoutes.helpFilePath(),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.helpFileLocation],
    }),
  }),
});

export const { useGetHelpFileQuery, useLazyGetHelpFileQuery } = helpFileApi;
