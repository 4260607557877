import PropTypes from 'prop-types';
import { prop, keys, forEach, pipe, forEachObjIndexed } from 'ramda';

export const shortDescriptionModel = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export default class PropTypesPresenter {
  constructor(propTypes, methods) {
    this.ownPropTypes = propTypes;

    pipe(
      keys,
      forEach(name => {
        this[`${name}`] = prop(name);
      }),
    )(propTypes);

    forEachObjIndexed((method, name) => {
      this[`${name}`] = method.bind(this);
    })(methods);
  }

  shape() {
    return PropTypes.shape(this.ownPropTypes);
  }

  tableData(bankAccount) {
    PropTypes.checkPropTypes(
      this.ownPropTypes,
      bankAccount,
      null,
      'BankAccountPresenter.tableData',
    );
    return bankAccount;
  }

  defaultValues(companyAddress) {
    PropTypes.checkPropTypes(
      this.ownPropTypes,
      companyAddress,
      null,
      'BankAccountPresenter.defaultValues',
    );
    return companyAddress;
  }
}
