import { StatusDisplayedNameByCode } from 'const';

import { IManager } from 'domain/manager/types';

import { formatDate } from 'utils/date';

export const managersColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  name: {
    displayedName: 'Company Name',
    isLink: true,
  },
  status: {
    displayedName: 'Status',
  },
  form2553FiledDate: {
    displayedName: '2553 Filed',
  },
  form2553AcceptedDate: {
    displayedName: '2553 Accepted',
  },
};

export const getTableData = (list: Array<IManager> = []) =>
  list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      status: StatusDisplayedNameByCode[rowData.status],
      form2553FiledDate: formatDate(rowData.form2553FiledDate),
      form2553AcceptedDate: formatDate(rowData.form2553AcceptedDate),
    },
  }));

export const getTableKeys = () => Object.keys(managersColumnConfig);
