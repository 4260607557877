import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

import { IAdministrativeAgent, IExtendedAdministrativeAgent } from './types';
import { IAdministrativeAgentUpdate } from './schemas/update';
import { IAdministrativeAgentCreate } from './schemas/create';

export const administrativeAgentApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getAdministrativeAgents: builder.query<Array<IAdministrativeAgent>, void>({
      query: () => ({
        url: apiRoutes.staffAdministrativeAgentsPath(),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.administrativeAgent),
    }),
    getAdministrativeAgent: builder.query<IExtendedAdministrativeAgent, number>({
      query: id => ({
        url: apiRoutes.staffAdministrativeAgentPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.administrativeAgent),
    }),
    updateAdministrativeAgent: builder.mutation<
      IExtendedAdministrativeAgent,
      { administrativeAgent: IAdministrativeAgentUpdate; id: number }
    >({
      query: ({ id, administrativeAgent }) => ({
        url: apiRoutes.staffAdministrativeAgentPath(id),
        method: 'PUT',
        data: administrativeAgent,
      }),
      invalidatesTags: [{ type: CACHE_TAG.administrativeAgent, id: 'LIST' }],
    }),
    createAdministrativeAgent: builder.mutation<
      IExtendedAdministrativeAgent,
      { administrativeAgent: IAdministrativeAgentCreate; id: number }
    >({
      query: data => ({
        url: apiRoutes.staffAdministrativeAgentsPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.administrativeAgent, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetAdministrativeAgentsQuery,
  useGetAdministrativeAgentQuery,
  useUpdateAdministrativeAgentMutation,
  useCreateAdministrativeAgentMutation,
} = administrativeAgentApi;
