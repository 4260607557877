import { ILoan, IExtendedLoan } from 'domain/loan/types';
import { ILoanQueryParams, ILoanResult } from 'domain/loan/schemas/common';
import { ILoanCreate } from 'domain/loan/schemas/create';
import { ILoanUpdate } from 'domain/loan/schemas/update';

import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

export const loansApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLoans: builder.query<ILoan<ILoanResult>, ILoanQueryParams>({
      query: params => ({
        url: apiRoutes.loansPath(),
        method: 'GET',
        params,
      }),
      transformResponse: (response: { data: ILoan<ILoanResult> }) => response?.data ?? null,
      providesTags: data => createTag.index(data?.results, CACHE_TAG.loan),
    }),
    getLoansPost: builder.query<ILoan<ILoanResult>, ILoanQueryParams>({
      query: data => ({
        url: apiRoutes.loansQueryPath(),
        method: 'POST',
        data,
      }),
      transformResponse: (response: { data: ILoan<ILoanResult> }) => response?.data ?? null,
      providesTags: data => createTag.index(data?.results, CACHE_TAG.loan),
    }),
    getLoan: builder.query({
      query: id => ({
        url: apiRoutes.loanPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.loan),
    }),
    createLoan: builder.mutation<IExtendedLoan, ILoanCreate>({
      query: data => ({
        url: apiRoutes.loansPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.loan, id: 'LIST' }],
    }),
    updateLoan: builder.mutation<IExtendedLoan, { id: number; loan: ILoanUpdate }>({
      query: ({ id, loan }) => ({
        url: apiRoutes.loanPath(id),
        method: 'PUT',
        data: loan,
      }),
      invalidatesTags: [{ type: CACHE_TAG.loan, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetLoansQuery,
  useGetLoanQuery,
  useCreateLoanMutation,
  useUpdateLoanMutation,
  useGetLoansPostQuery,
} = loansApi;
