import { isNil } from 'ramda';

import { IExtendedLoan } from 'domain/extendedLoan/types';

import { formatDate, isBeforeTodayDate } from 'utils/date';

export const upcomingParRepaysColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  id: {
    displayedName: 'PID',
  },
  dealName: {
    displayedName: 'Property Name',
    isLink: true,
    withTags: true,
    width: '350px',
  },
  parRepayNoticeDate: {
    displayedName: 'Par Repay Notice Date',
  },
  parRepayNoticeSentDate: {
    displayedName: 'Par Repay Notice Sent Date',
  },
  parRepayType: {
    displayedName: 'Par Repay Type',
  },
  parRepayDate: {
    displayedName: 'Par Repay Date',
  },
  parRepayConservativeDate: {
    displayedName: 'Par Repay Date (Conservative)',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  masterServicerShortName: {
    displayedName: 'Master Short',
  },
  masterServicerLoanNumber: {
    displayedName: 'Master #',
    maxWidth: '130px',
  },
  alternativeServicerShortName: {
    displayedName: 'Sub Short',
  },
  loanServicerLoanNumber: {
    displayedName: 'Sub Loan #',
  },
  custodianShortName: {
    displayedName: 'Custodian',
  },
  custodianAccountNumber: {
    displayedName: 'Custodian #',
  },
};

export const getTableColumnsKeys = () => Object.keys(upcomingParRepaysColumnConfig);

export const getTableData = (list: Array<IExtendedLoan>) => {
  if (isNil(list)) return null;
  return list.map(rowData => ({
    id: rowData.id,
    isHighlighted: isBeforeTodayDate(rowData.parRepayDate),
    data: {
      ...rowData,
      parRepayNoticeDate: formatDate(rowData.parRepayNoticeDate),
      parRepayNoticeSentDate: formatDate(rowData.parRepayNoticeSentDate),
      parRepayDate: formatDate(rowData.parRepayDate),
      parRepayConservativeDate: formatDate(rowData.parRepayConservativeDate),
      maturityDate: formatDate(rowData.maturityDate),
      parRepayNoticeLender: formatDate(rowData.parRepayNoticeLenderDate),
      parRepayNoticeCustodian: formatDate(rowData.parRepayNoticeCustodianDate),
    },
  }));
};
